import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/Layout';
import { Container, Heading, OrderSummary, Faqs } from 'components';

function FaqsPage({ data }) {
  return (
    <Layout pageTitle="FAQS">
      <Container>
        <Heading className="text-center">FAQS</Heading>
        <Faqs props={data?.allContentfulFaqs.edges} />
      </Container>
    </Layout>
  );
}

export default FaqsPage;

export const query = graphql`
  query faqsPageQuery {
    allContentfulFaqs(sort: { fields: order }) {
      edges {
        node {
          body {
            raw
          }
          title
          order
        }
      }
    }
  }
`;
